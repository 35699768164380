import Konva from 'konva'
import { RefObject } from 'react'
import { createStage } from 'utils/konva'
import { LocalTimelineSettings } from 'components/ps-chart/local-timeline/LocalTimelineSettings'
import { Flag } from 'components/ps-chart/models/Flag'
import { KonvaFlag, Size } from 'components/ps-chart/local-timeline/flags/KonvaFlag'
import { Scale } from 'components/ps-chart/local-timeline/Scale'
import { FlagsState } from 'components/ps-chart/stores/FlagsStore'
import { VideoPlayerState, VideoPlayerStore } from 'components/ps-chart/stores/VideoPlayerStore'
import { KonvaVideoPointer } from 'components/ps-chart/local-timeline/video-pointer/KonvaVideoPointer'
import { GhostIndicator } from 'components/video-timeline/GhostIndicator'
import { PsChartFeatures } from 'components/ps-chart/PsChartStore'
import {
  AnnotationIdAndType,
  AnnotationsState,
  PinType,
} from 'components/ps-chart/stores/AnnotationsStore'
import { AnnotationElement } from 'components/ps-chart/local-timeline/annotations/AnnotationElement'
import { AnnotationDto } from 'api/models'

export type OnFlagAdd = (time: number) => void
export type OnVideoPointerClick = (time: number, isDragStart: boolean, isDragEnd: boolean) => void
export type OnFlagPosChange = (time: number, id: number, cid?: number) => void
export type OnFlagClick = (id: number, cid?: number) => void
export type OnShowHover = (time: number | null) => void
export type OnAnnotationTimeChange = (number: number, idAndPinType: AnnotationIdAndType) => void
export type OnAnnotationHover = (idAndPinType: AnnotationIdAndType | null) => void
export type OnAnnotationClick = (idAndPinType: AnnotationIdAndType | null) => void
export type OnAnnotationDragStart = (idAndPinType: AnnotationIdAndType) => void

export interface LocalTimelineListener {
  onFlagAdd: OnFlagAdd
  onFlagPosChangeLocally: OnFlagPosChange
  onFlagPosChange: OnFlagPosChange
  onFlagClick: OnFlagClick
  onShowHoverFlag: OnShowHover
  onShowHoverVideoPointer: OnShowHover
  onVideoPointerClick: OnVideoPointerClick
  onAnnotationPosChangeLocally: OnAnnotationTimeChange
  onAnnotationPosChange: OnAnnotationTimeChange
  onAnnotationHover: OnAnnotationHover
  onAnnotationClick: OnAnnotationClick
  onAnnotationDragStart: OnAnnotationDragStart
}

interface FlagElement {
  flag: Flag
  konvaFlag: KonvaFlag
}

export class LocalTimelineRenderer {
  private readonly settings: LocalTimelineSettings
  private readonly listener: LocalTimelineListener
  private readonly height: number
  private width: number
  private timePerPx: number
  private readonly min: number
  private readonly max: number
  private gridLines: number[]
  private chartFeatures: PsChartFeatures

  private readonly stage: Konva.Stage
  private readonly scaleLayer: Konva.Layer
  private readonly hoverLayer: Konva.Layer
  private readonly contentLayer: Konva.Layer
  private readonly contentRect: Konva.Rect
  private readonly contentHoverBgRect: Konva.Rect
  /** Background layer. It should be behind all other layers. */
  private readonly bgLayer: Konva.Layer

  private readonly videoPointer: KonvaVideoPointer
  private readonly ghostIndicator: GhostIndicator

  private start: number
  private end: number
  private konvaScales: Scale[] = []

  private readonly flagElements: FlagElement[] = []
  private flagsShowLabels = false
  private selectedFlagId: number | null = null
  private readonly hoverFlag: Konva.Line
  private readonly flagSize: Size

  private leftVideoPointerLimit = 0
  private rightVideoPointerLimit = 0
  private hasFullVideoData: boolean

  private readonly annotationElements: AnnotationElement[] = []

  constructor(
    containerId: string,
    containerRef: RefObject<HTMLDivElement>,
    min: number,
    max: number,
    timePerPx: number,
    gridLines: number[],
    flagsState: FlagsState,
    videoStore: VideoPlayerStore,
    annotationState: AnnotationsState,
    chartFeatures: PsChartFeatures,
    settings: LocalTimelineSettings,
    listener: LocalTimelineListener,
  ) {
    this.listener = listener
    this.settings = settings
    this.chartFeatures = chartFeatures
    this.min = min
    this.max = max
    this.width = containerRef.current?.getBoundingClientRect().width ?? 0
    this.height = containerRef.current?.getBoundingClientRect().height ?? 0
    this.start = this.min
    this.end = this.max
    this.timePerPx = timePerPx
    this.gridLines = gridLines
    this.flagSize = KonvaFlag.calcSize(this.height, this.settings.flags)

    this.stage = createStage(containerId, this.width, this.height)

    this.scaleLayer = new Konva.Layer()
    this.stage.add(this.scaleLayer)

    this.bgLayer = new Konva.Layer()
    this.stage.add(this.bgLayer)
    this.bgLayer.moveToBottom()

    this.hoverLayer = new Konva.Layer()
    this.stage.add(this.hoverLayer)
    this.hoverFlag = KonvaFlag.drawFlag(
      this.settings.flags.hoverFlagColor,
      '',
      this.flagSize,
      this.settings.flags,
    )
    this.hoverFlag.visible(false)
    this.hoverLayer.add(this.hoverFlag)

    this.ghostIndicator = this.createGhostIndicator(videoStore)
    this.stage.add(this.ghostIndicator.layer)
    // Ghost indicator hover bg have to be added here to be on the very bottom, behind the grid lines
    this.bgLayer.add(this.ghostIndicator.hoverBgRect)

    this.contentLayer = new Konva.Layer()
    this.stage.add(this.contentLayer)

    this.contentRect = this.createContentRect()
    this.contentLayer.add(this.contentRect)

    this.contentHoverBgRect = this.createContentRect()
    this.contentHoverBgRect.fill(this.settings.flags.hoverColor)
    this.contentHoverBgRect.hide()
    this.bgLayer.add(this.contentHoverBgRect)

    this.hasFullVideoData = videoStore.hasFullData
    this.videoPointer = this.createVideoPointer()

    this.updateState(
      this.width,
      this.start,
      this.end,
      timePerPx,
      gridLines,
      flagsState,
      videoStore,
      annotationState,
    )
  }

  private createGhostIndicator(videoStore: VideoPlayerStore) {
    const ghostIndicatorHeight = Math.round(
      this.height * this.settings.ghostIndicatorHeightCoefficient,
    )
    return new GhostIndicator(
      this.width,
      this.height,
      ghostIndicatorHeight,
      this.timePerPx,
      this.settings.ghostIndicator,
      videoStore,
      true,
    )
  }

  private createContentRect() {
    const contentHeight = Math.round(this.height * this.settings.contentHeightCoefficient)
    return new Konva.Rect({
      x: 0,
      width: this.width,
      y: this.height - contentHeight,
      height: contentHeight,
    })
  }

  addEventListeners() {
    if (this.chartFeatures.flags) {
      this.contentRect.on('click', () => {
        const relPos = this.contentRect.getRelativePointerPosition()
        const { x } = relPos

        const time = Math.round(this.start + x * this.timePerPx)
        this.listener.onFlagAdd(time)
      })

      this.contentRect.on('mousemove', this.showHover)
      this.contentRect.on('mouseleave', this.hideHover)
    }

    this.ghostIndicator.addEventListeners()
  }

  private showHover = () => {
    const pointerPos = this.contentRect.getRelativePointerPosition()
    const time = this.xToTime(pointerPos.x)
    this.showHoverFlag(pointerPos.x)
    this.listener.onShowHoverFlag(time)
    this.contentHoverBgRect.show()
  }

  private hideHover = () => {
    this.hideHoverFlag()
    this.contentHoverBgRect.hide()
  }

  private hideHoverFlag() {
    this.hoverFlag.visible(false)
    this.listener.onShowHoverFlag(null)
  }

  removeEventListeners() {
    this.contentRect.off('click')
    this.contentRect.off('mouseover')
    this.contentRect.off('mouseleave')
    this.ghostIndicator.removeEventListeners()
  }

  render(videoState: VideoPlayerState) {
    this.renderScale()
    this.renderFlags()
    this.renderAnnotations()
    this.renderVideoPointer(videoState)
  }

  private getFlagElementByCid(cid: number): FlagElement | undefined {
    return this.flagElements.find((flagElement) => flagElement.flag.cid === cid)
  }

  private getFlagElementById(id: number): FlagElement | undefined {
    return this.flagElements.find((element) => element.flag.id === id)
  }

  private getAnnotationElementById(id: number): AnnotationElement | undefined {
    return this.annotationElements.find((element) => element.annotation.id === id)
  }

  private getAnnotationElementByIdOrCid(
    id: number,
    cid?: number | null,
  ): AnnotationElement | undefined {
    return this.annotationElements.find((element) =>
      cid ? element.annotation.cid === cid : element.annotation.id === id,
    )
  }

  updateState(
    width: number,
    xStart: number,
    xEnd: number,
    timePerPx: number,
    gridLines: number[],
    flagsState: FlagsState,
    videoState: VideoPlayerState,
    annotationsState: AnnotationsState,
  ) {
    this.width = width
    this.stage.width(width)
    this.contentRect.width(width)
    this.contentHoverBgRect.width(width)
    this.timePerPx = timePerPx
    this.start = xStart
    this.end = xEnd
    this.gridLines = gridLines
    this.hasFullVideoData = videoState.hasFullData
    this.leftVideoPointerLimit = this.timeToX(
      Math.max(xStart, -(videoState.videoAndTraceDeltaNanos ?? 0)),
    )
    this.rightVideoPointerLimit = this.timeToX(
      videoState.videoLengthMicros * 1_000 - (videoState.videoAndTraceDeltaNanos ?? 0),
    )
    this.updateFlags(flagsState)
    this.updateAnnotations(annotationsState)
    this.render(videoState)
    this.renderAnnotations()
    this.ghostIndicator.update(this.width, this.timePerPx, this.start)
  }

  updateFlags(flagsState: FlagsState) {
    this.flagsShowLabels = flagsState.showLabels
    this.selectedFlagId = flagsState.selectedFlagId
    flagsState.flags.forEach((updatedFlag) => {
      // Add or update flag
      const flagElement = updatedFlag.cid
        ? this.getFlagElementByCid(updatedFlag.cid)
        : this.getFlagElementById(updatedFlag.id)
      if (!flagElement) {
        this.addFlag(updatedFlag, flagsState.showLabels)
      } else {
        flagElement.flag = updatedFlag
        flagElement.konvaFlag.updateFlag(updatedFlag, flagsState.showLabels)
      }
    })

    // Remove deleted elements
    const updatedFlagIds = flagsState.flags.map((flag) => flag.id)
    const deletedFlagElements = this.flagElements.filter(
      (flagElement) => !updatedFlagIds.includes(flagElement.flag.id),
    )
    const flagIdsToRemove: Set<number> = new Set()
    deletedFlagElements.forEach((deletedFlagElement) =>
      flagIdsToRemove.add(deletedFlagElement.flag.id),
    )
    flagIdsToRemove.forEach((id) => this.removeFlag(id))
    this.renderFlags()
  }

  private addFlag(flag: Flag, showLabels: boolean) {
    const { id } = flag
    const { cid } = flag
    const konvaFlag = new KonvaFlag(flag, this.height, showLabels, this.settings.flags)
    konvaFlag.group.on('dragmove', () => {
      if (konvaFlag.x < 0) {
        konvaFlag.x = 0
      }
      if (konvaFlag.x > this.width) {
        konvaFlag.x = this.width
      }
      konvaFlag.y = 0
      this.listener.onFlagPosChangeLocally(this.xToTime(konvaFlag.x), id, cid)
    })
    konvaFlag.group.on('dragend', () => {
      this.listener.onFlagPosChange(this.xToTime(konvaFlag.x), id, cid)
    })
    konvaFlag.group.on('click', () => {
      this.listener.onFlagClick(id, cid)
      konvaFlag.group.moveToTop()
    })

    konvaFlag.updateFlag(flag, showLabels)
    const flagElement = { flag: flag, konvaFlag: konvaFlag }
    this.flagElements.push(flagElement)
  }

  private removeFlag(id: number) {
    const flagElement = this.getFlagElementById(id)
    if (flagElement) {
      flagElement.konvaFlag.removeAndUnsubscribe()
      const index = this.flagElements.indexOf(flagElement)
      this.flagElements.splice(index, 1)
    }
  }

  private renderFlags() {
    for (const flagElement of this.flagElements) {
      const { flag, konvaFlag } = flagElement
      if (this.chartFeatures.flags) {
        konvaFlag.updateFlag(flag, this.flagsShowLabels)
        if (flag.id === this.selectedFlagId) {
          konvaFlag.select()
        } else {
          konvaFlag.clearSelection()
        }
        if (this.start <= flag.time && flag.time <= this.end) {
          konvaFlag.x = this.timeToX(flag.time)
          if (this.contentLayer.children?.indexOf(konvaFlag.group) ?? -1 < 0) {
            this.contentLayer.add(konvaFlag.group)
          }
        } else {
          konvaFlag.remove()
        }
      } else {
        konvaFlag.remove()
      }
    }
  }

  private updateAnnotations(annotationsState: AnnotationsState) {
    if (annotationsState.featureState.enabled) {
      annotationsState.annotations.forEach((updatedAnnotation) => {
        const annotationElement = this.getAnnotationElementByIdOrCid(
          updatedAnnotation.id,
          updatedAnnotation.cid,
        )
        if (!annotationElement) {
          const newAnnotation = this.createAnnotation(updatedAnnotation)
          this.annotationElements.push(newAnnotation)
          this.contentLayer.add(
            newAnnotation.konvaAnnotation.connectionLine,
            newAnnotation.konvaAnnotation.actionPin.pinGroup,
            newAnnotation.konvaAnnotation.reactionPin.pinGroup,
          )
        } else {
          annotationElement.updateAnnotation(
            this.width,
            this.height,
            updatedAnnotation,
            annotationsState.hoveredId,
            annotationsState.selectedId,
            this.chartFeatures.annotations,
          )
        }
      })

      const updatedAnnotationIds = annotationsState.annotations.map((annotation) => annotation.id)
      const deletedAnnotationElements = this.annotationElements.filter(
        (annotationEl) => !updatedAnnotationIds.includes(annotationEl.annotation.id),
      )
      const annotationIdsToRemove: Set<number> = new Set()
      deletedAnnotationElements.forEach((deletedEl) =>
        annotationIdsToRemove.add(deletedEl.annotation.id),
      )
      annotationIdsToRemove.forEach((id) => this.removeAnnotation(id))

      if (annotationsState.selectedId !== null) {
        const annotationEl = this.annotationElements.find(
          (el) => el.annotation.id === annotationsState.selectedId!.id,
        )
        if (annotationEl) {
          if (annotationsState.selectedId.type === PinType.ACTION) {
            annotationEl.konvaAnnotation.actionPin.moveToTop()
          } else {
            annotationEl.konvaAnnotation.reactionPin.moveToTop()
          }
        }
      }
    }
  }

  private createAnnotation(updatedAnnotation: AnnotationDto): AnnotationElement {
    const annotationElement = new AnnotationElement(
      this.width,
      this.height,
      updatedAnnotation,
      this.settings.annotations,
      this.chartFeatures.annotations,
    )
    const { konvaAnnotation } = annotationElement

    const { actionPin } = konvaAnnotation
    const { reactionPin } = konvaAnnotation

    actionPin.pinGroup.addEventListener('dragmove', () => {
      const idAndType = annotationElement.idAndType(PinType.ACTION)
      this.listener.onAnnotationPosChangeLocally(this.xToTime(actionPin.x), idAndType)
    })
    actionPin.pinGroup.addEventListener('dragstart', () => {
      const idAndType = annotationElement.idAndType(PinType.ACTION)
      this.listener.onAnnotationDragStart(idAndType)
    })
    actionPin.pinGroup.addEventListener('dragend', () => {
      if (actionPin.draggable) {
        const idAndType = annotationElement.idAndType(PinType.ACTION)
        this.listener.onAnnotationPosChange(this.xToTime(actionPin.x), idAndType)
      }
    })
    reactionPin.pinGroup.addEventListener('dragmove', () => {
      const idAndType = annotationElement.idAndType(PinType.REACTION)
      this.listener.onAnnotationPosChangeLocally(this.xToTime(reactionPin.x), idAndType)
    })
    reactionPin.pinGroup.addEventListener('dragstart', () => {
      const idAndType = annotationElement.idAndType(PinType.REACTION)
      this.listener.onAnnotationDragStart(idAndType)
    })
    reactionPin.pinGroup.addEventListener('dragend', () => {
      if (reactionPin.draggable) {
        const idAndType = annotationElement.idAndType(PinType.REACTION)
        this.listener.onAnnotationPosChange(this.xToTime(reactionPin.x), idAndType)
      }
    })
    actionPin.pinGroup.addEventListener('mouseover', () => {
      const idAndType = annotationElement.idAndType(PinType.ACTION)
      this.listener.onAnnotationHover(idAndType)
    })
    actionPin.pinGroup.addEventListener('mouseleave', () => this.listener.onAnnotationHover(null))
    reactionPin.pinGroup.addEventListener('mouseover', () => {
      const idAndType = annotationElement.idAndType(PinType.REACTION)
      this.listener.onAnnotationHover(idAndType)
    })
    reactionPin.pinGroup.addEventListener('mouseleave', () => this.listener.onAnnotationHover(null))
    actionPin.pinGroup.addEventListener('click', () => {
      const idAndType = annotationElement.idAndType(PinType.ACTION)
      this.listener.onAnnotationClick(idAndType)
    })
    reactionPin.pinGroup.addEventListener('click', () => {
      const idAndType = annotationElement.idAndType(PinType.REACTION)
      this.listener.onAnnotationClick(idAndType)
    })
    return annotationElement
  }

  private renderAnnotations() {
    if (!this.chartFeatures.annotations.enabled) {
      return
    }
    this.annotationElements.forEach((annotationElement) => {
      const annotation = annotationElement.konvaAnnotation

      const isActionVisible = annotation.actionPin.hasTimeAndIsInside(this.start, this.end)
      if (isActionVisible) {
        annotation.actionPin.x = this.timeToX(annotation.actionPin.time!)
        this.contentLayer.add(annotation.actionPin.pinGroup)
      } else {
        annotation.actionPin.pinGroup.remove()
      }

      const isReactionVisible = annotation.reactionPin.hasTimeAndIsInside(this.start, this.end)
      if (isReactionVisible) {
        annotation.reactionPin.x = this.timeToX(annotation.reactionPin.time!)
        this.contentLayer.add(annotation.reactionPin.pinGroup)
      } else {
        annotation.reactionPin.pinGroup.remove()
      }

      const isActionOrReactionVisible = isActionVisible || isReactionVisible
      if (
        isActionOrReactionVisible &&
        annotation.actionPin.time !== null &&
        annotation.reactionPin.time !== null
      ) {
        const actionX = isActionVisible
          ? annotation.actionPin.x
          : this.timeToX(annotation.actionPin.time)
        const reactionX = isReactionVisible
          ? annotation.reactionPin.x
          : this.timeToX(annotation.reactionPin.time)
        const middleX = actionX + Math.round((reactionX - actionX) / 2)
        const middleY = Math.round(
          this.height - Math.round(this.settings.annotations.pinHeight / 2),
        )
        annotation.connectionLine.points([
          actionX,
          this.height,
          middleX,
          middleY,
          reactionX,
          this.height,
        ])
        annotation.connectionLine.moveToBottom()
        this.contentLayer.add(annotation.connectionLine)
      } else {
        annotation.connectionLine.remove()
      }
    })
  }

  private renderScale() {
    const layer = this.scaleLayer
    layer.removeChildren()
    const scaleWidth = this.gridLines[1] - this.gridLines[0]
    const milliSecond = 1_000_000

    this.gridLines.forEach((xTime, index) => {
      const x = this.timeToX(xTime)
      let time = this.timeToText(xTime, scaleWidth)
      const microSeconds = String(xTime).slice(-6, -3)
      if (scaleWidth < milliSecond) {
        time = `${time},${microSeconds}`
      }

      let scale = this.konvaScales[index]
      if (scale) {
        scale.update(x, time)
      } else {
        scale = new Scale(this.settings, x, time)
        this.konvaScales.push(scale)
      }

      layer.add(scale.group)
    })
  }

  private timeToText(xTime: number, scaleWidth: number) {
    return new Date((xTime - this.min) / 1_000_000)
      .toISOString()
      .slice(14, scaleWidth >= 1_000_000_000 ? -5 : -1)
  }

  private timeToX(time: number): number {
    return Math.round((time - this.start) / this.timePerPx)
  }

  private xToTime(x: number): number {
    return Math.round(x * this.timePerPx + this.start)
  }

  renderVideoPointer(videoState: VideoPlayerState) {
    const time = videoState.traceVideoPointerTimeNanos

    if (time >= this.end) {
      // don't show video pointer if it is out of the view
      this.videoPointer.group.visible(false)
    } else if (videoState.hasFullData && this.start <= time && time <= this.end) {
      this.videoPointer.group.visible(true)
      const x = this.timeToX(time)
      if (x === this.width) {
        // To match with the upper right limiter when zoom = 1
        this.videoPointer.x = x - 0.5
      } else {
        this.videoPointer.x = x
      }
      this.videoPointer.updateText(this.timeToText(time, this.settings.videoPointer.rectWidth))
    } else {
      this.videoPointer.group.visible(false)
    }
  }

  private showHoverFlag(x: number) {
    this.hoverFlag.visible(true)
    this.hoverFlag.x(x)
  }

  private createVideoPointer(): KonvaVideoPointer {
    const konvaVideoPointer = new KonvaVideoPointer(this.height, this.settings.videoPointer)
    konvaVideoPointer.group.on('dragstart', () => this.onVideoPointerMove(true, false))
    konvaVideoPointer.group.on('dragend', () => this.onVideoPointerMove(false, true))
    konvaVideoPointer.group.on('dragmove', () => this.onVideoPointerMove(false, false))
    this.contentLayer.add(konvaVideoPointer.group)
    konvaVideoPointer.updateText(this.timeToText(0, this.settings.videoPointer.rectWidth))
    return konvaVideoPointer
  }

  private onVideoPointerMove(dragStart: boolean, dragEnd: boolean) {
    if (this.videoPointer.x < 0 || this.videoPointer.x <= this.leftVideoPointerLimit) {
      this.videoPointer.x = Math.max(this.leftVideoPointerLimit, 0)
    }
    if (this.videoPointer.x > this.rightVideoPointerLimit) {
      this.videoPointer.x = this.rightVideoPointerLimit
    }
    if (this.videoPointer.x > this.width) {
      this.videoPointer.x = this.width - 0.5
    }
    this.videoPointer.y = 0
    const time = this.xToTime(this.videoPointer.x)
    this.listener.onVideoPointerClick(time, dragStart, dragEnd)
    if (dragEnd) {
      setTimeout(() => this.ghostIndicator.handleMouseLeave(), 0)
    }
  }

  private getAnnotationIdAndType(
    annotationAndBinding: AnnotationDto,
    type: PinType,
  ): AnnotationIdAndType {
    return { id: annotationAndBinding.id, type: type }
  }

  private removeAnnotation(id: number) {
    const annotationElement = this.getAnnotationElementById(id)
    if (annotationElement) {
      annotationElement.konvaAnnotation.removeAndUnsubscribe()
      const index = this.annotationElements.indexOf(annotationElement)
      this.annotationElements.splice(index, 1)
    }
  }
}
