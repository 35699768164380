import React from 'react'
import { DashboardRunDto } from 'api/__generated__/api-types'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { observer } from 'mobx-react-lite'
import { RunDetails } from 'components/flows/ra/runs/RunDetails'
import { RunSummary } from 'components/flows/ra/runs/RunSummary'
import { useTranslation } from 'react-i18next'

export interface RunsTabProps {
  raStore: RaMvpStore
}

export const RunsTab = observer(function RunsTab(props: RunsTabProps) {
  const { t } = useTranslation()
  const { raStore } = props
  return (
    <div className="ml-flowLeftPadding mr-flowRightPadding mb-8 px-2 text-gray-normal space-y-4 w-[80ch]">
      {raStore.runs.length === 0 && <p className="text-normal">{t('ra.flow.emptyRuns')}</p>}
      {raStore.runs.map((run) => (
        <Run key={run.id} raStore={raStore} run={run} />
      ))}
    </div>
  )
})

interface RunProps {
  run: DashboardRunDto
  raStore: RaMvpStore
}

const Run = ({ raStore, run }: RunProps) => {
  return (
    <details className="text-normal leading-8">
      <summary>
        <RunSummary run={run} />
      </summary>
      <div className="ml-5 py-2 border rounded border-gray-faded">
        <RunDetails raStore={raStore} run={run} />
      </div>
    </details>
  )
}
