import resolveConfig from 'tailwindcss/resolveConfig'

import { colors } from 'utils/styles/colors'
import tailwindConfig from 'utils/../../tailwind.config'
// @ts-ignore
const fullConfig = resolveConfig(tailwindConfig)

export const chartSettingsMvp = Object.freeze({
  header: Object.freeze({
    heightPx: 35,
  }),

  line: Object.freeze({
    width: 2,
    color: colors.state.good,
    dotRadius: 3,
  }),

  grid: Object.freeze({
    horizontal: Object.freeze({
      width: 1,
      color: colors.gray.strokeLight,
    }),
    vertical: Object.freeze({
      width: 1,
      color: colors.gray.strokeLight,
      dashPattern: [4, 4],
    }),
    step: Object.freeze({
      heightPx: parseInt(fullConfig.theme?.height?.raDashboardLineHeight ?? '0'),
      count: 3,
      minScaleNs: 1_000,
    }),
  }),
})
