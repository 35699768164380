import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlowDto, ProjectDto } from 'api/__generated__/api-types'
import { Button, ButtonVariantEnum } from 'components/Button'
import { SettingsField } from 'components/flows/ra/settings/SettingsField'
import { AutomationScript } from 'components/flows/ra/settings/AutomationScript'
import { SettingsModal } from 'components/flows/ra/settings/modal/SettingsModal'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { capitalizeFirstLetter } from 'utils/stringUtils'
import {
  InstrumentedRunCondition,
  prettifyRunCondition,
} from 'components/flows/ra/settings/settingUtils'

export interface SettingsTabProps {
  projectDto: ProjectDto
  flowDto: FlowDto
  raStore: RaMvpStore
  isAdmin: boolean
}

export const SettingsTab = (props: SettingsTabProps) => {
  const { flowDto, projectDto, raStore, isAdmin } = props
  const { t } = useTranslation()

  const [showChangeModal, setShowChangeModal] = useState(false)

  const prepareText = useCallback((): PreparedSettings => {
    const notSet = t('ra.flow.settings.notSet')
    const devices =
      flowDto.automationConfig?.devices && flowDto.automationConfig.devices.length > 0
        ? flowDto.automationConfig.devices.map((device) => device.name).join(', ')
        : notSet
    return {
      userFlowId: flowDto.automationConfig?.raUserFlowId || notSet,
      devices: devices,
      priority: capitalizeFirstLetter(flowDto.automationConfig?.priority.toLowerCase() || notSet),
      runCount: flowDto.automationConfig?.runCount.toString() || notSet,
      runCountInstrumented: flowDto.automationConfig?.runCountInstrumented.toString() || notSet,
      instrumentedRunCondition: prettifyRunCondition(
        flowDto.automationConfig?.instrumentedRunCondition ??
          InstrumentedRunCondition.ON_ANY_CHANGE,
      ),
      forceStop: flowDto.automationConfig?.forceStop ? t('enabled') : t('disabled'),
      clearData: flowDto.automationConfig?.clearData ? t('enabled') : t('disabled'),
      switchFreq: flowDto.automationConfig?.switchFrequency || 0,
      instrumentedRunOnRegression: flowDto.automationConfig?.instrumentedRunOnRegression
        ? 'enabled'
        : 'disabled',
    }
  }, [flowDto, t])

  const data = prepareText()

  return (
    <div className="ml-flowLeftPadding mr-flowRightPadding px-2">
      <div className="space-y-4 text-normal">
        <SettingsField label={t('ra.flow.settings.userFlowId.label')}>
          {data.userFlowId}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.devices.label')}>{data.devices}</SettingsField>
        <SettingsField label={t('ra.flow.settings.priority.label')}>{data.priority}</SettingsField>
        <SettingsField label={t('ra.flow.settings.runCount.label')}>{data.runCount}</SettingsField>
        <SettingsField label={t('ra.flow.settings.runCountInstrumented.label')}>
          {data.runCountInstrumented}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.switchFreq.label')}>
          {data.switchFreq}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.instrumentedRunCondition.label')}>
          {data.instrumentedRunCondition}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.forceStop.label')}>
          {data.forceStop}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.clearData.label')}>
          {data.clearData}
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.automationScript.label')}>
          <AutomationScript script={flowDto.automationConfig?.automationScript} />
        </SettingsField>
        <SettingsField label={t('ra.flow.settings.automationScript.labelSetup')}>
          <AutomationScript script={flowDto.automationConfig?.setupScript} />
        </SettingsField>
      </div>
      <Button
        className="mt-8"
        variant={ButtonVariantEnum.Outlined}
        isSmall
        onClick={() => setShowChangeModal(true)}
      >
        {t('ra.flow.settings.change')}
      </Button>
      <SettingsModal
        isOpen={showChangeModal}
        onClose={() => setShowChangeModal(false)}
        flowDto={flowDto}
        projectDto={projectDto}
        raStore={raStore}
        isAdmin={isAdmin}
      />
    </div>
  )
}

interface PreparedSettings {
  userFlowId: string
  devices: string
  priority: string
  runCount: string
  runCountInstrumented: string
  instrumentedRunCondition: string
  forceStop: string
  clearData: string
  switchFreq: number
  instrumentedRunOnRegression: string
}
