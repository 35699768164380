import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react-lite'
import { Tab } from '@headlessui/react'

import { PsChartStore } from 'components/ps-chart/PsChartStore'
import { Icon } from 'components/Icon'
import { TabLabel } from 'components/ps-chart/details-view/TabLabel'
import { FlagDetails } from 'components/ps-chart/details-view/flags/FlagDetails'
import { SliceConnections } from 'components/ps-chart/details-view/SliceConnections'
import { useDisablePinchZoom } from 'hooks/useDisablePinchZoom'
import { AnnotationsTab } from 'components/ps-chart/details-view/annotations/AnnotationsTab'
import { SlicesMeasure } from 'components/ps-chart/details-view/SlicesMeasure'
import { ActionTooltip } from 'components/ActionTooltip'
import { useHotKeys } from 'components/ps-chart/hooks/useHotKeys'
import { ELEMENTS_IDS } from 'components/ps-chart/elementsIds'

export const DetailsView = observer(function DetailsView({
  psChartStore,
}: {
  psChartStore: PsChartStore
}) {
  const { uiState } = psChartStore

  const [showContent, setShowContent] = useState(true)

  const { t } = useTranslation()

  const onCollapse = useCallback(() => {
    uiState.setRightCollapsed(true)
    setShowContent(false)
  }, [uiState])

  const onExpand = useCallback(() => {
    uiState.setRightCollapsed(false)
  }, [uiState])

  useHotKeys(
    ['BracketRight'],
    () => {
      if (uiState.isRightCollapsed) {
        onExpand()
      } else {
        onCollapse()
      }
    },
    psChartStore.isEnabledListeners,
  )

  const onTabChange = useCallback(
    (index: number) => {
      uiState.setSelectedTabIndex(index)
    },
    [uiState],
  )

  const detailsRef = useRef<HTMLDivElement>(null)
  useDisablePinchZoom(detailsRef)

  return (
    <>
      {uiState.isRightCollapsed ? (
        <div
          id={ELEMENTS_IDS.CHART_DETAILS_VIEW}
          ref={detailsRef}
          className="w-[32px] bg-dark-dark3 transition-all cursor-pointer flex items-center"
          onClick={onExpand}
          data-tid="detailsview-open"
        >
          <ActionTooltip tooltipId="showRightPanel" place="left">
            <div className="flex items-center">
              <Icon icon="arrow-drop-l" className="text-gray-normal text-icon" />
            </div>
          </ActionTooltip>
        </div>
      ) : (
        <div
          id={ELEMENTS_IDS.CHART_DETAILS_VIEW}
          ref={detailsRef}
          className="w-[320px] bg-dark-dark3 transition-all flex flex-col"
          onTransitionEnd={() => setShowContent(true)}
        >
          {showContent && (
            <Tab.Group onChange={onTabChange} selectedIndex={uiState.selectedTabIndex}>
              <ActionTooltip tooltipId="hideRightPanel" place="bottom" offsetFromTarget={8}>
                <div
                  className="w-[32px] h-[32px] bg-dark-dark5 absolute right-0 mt-[4px] mr-[4px] cursor-pointer"
                  onClick={onCollapse}
                  data-tid="detailsview-close"
                >
                  <Icon icon="arrow-drop-r" className="text-gray-normal text-icon" />
                </div>
              </ActionTooltip>
              <Tab.List className="bg-dark-dark5">
                <TabLabel>{t('psChart.details.detailsTab')}</TabLabel>
                {psChartStore.chartFeatures.measurementTool && (
                  <TabLabel>{t('psChart.details.measureTab')}</TabLabel>
                )}
              </Tab.List>
              <Tab.Panels className="flex-grow flex">
                <Tab.Panel className="focus:outline-none container relative">
                  {psChartStore.chartFeatures.annotations.draggable ? (
                    <AnnotationsTab psChartStore={psChartStore} />
                  ) : psChartStore.flagsStore.selectedFlagId ? (
                    <FlagDetails flagsStore={psChartStore.flagsStore} />
                  ) : psChartStore.traceAnalyzeStore.selectedSlice ? (
                    <div className="h-details-panel pt-[16px] pl-[16px] pr-[10px] overflow-y-scroll">
                      <SliceConnections psChartStore={psChartStore} />
                    </div>
                  ) : (
                    <div className="p-[24px] text-small font-medium text-gray-normal">
                      {t('psChart.details.emptySliceSelect')}
                    </div>
                  )}
                </Tab.Panel>
                {psChartStore.chartFeatures.measurementTool && (
                  <Tab.Panel className="flex-grow flex focus:outline-none">
                    <SlicesMeasure psChartStore={psChartStore} />
                  </Tab.Panel>
                )}
              </Tab.Panels>
            </Tab.Group>
          )}
        </div>
      )}
    </>
  )
})
