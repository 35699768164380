import { DashboardRunDto } from 'api/__generated__/api-types'
import React from 'react'
import { DataCol } from 'components/flows/ra/runs/details/utils/DataCol'
import { GridHeader } from 'components/flows/ra/runs/details/utils/GridHeader'
import { useTranslation } from 'react-i18next'
import { prettifyDate } from 'components/flows/ra/runs/runUtils'

interface BuildProps {
  run: DashboardRunDto
}

export const Builds = ({ run }: BuildProps) => {
  const { t } = useTranslation()
  const current = run.results.current.nonInstrumented?.build
  const prev = run.results.previous?.nonInstrumented?.build
  if (!current) {
    return null
  }
  return (
    <div>
      <p className="mb-1">
        <span>Builds</span>
      </p>
      <div>
        <GridHeader />
        <div className="grid grid-cols-3">
          <DataCol isDescription>{t('ra.flow.build.id.label')}</DataCol>
          <DataCol>{current.id}</DataCol>
          <DataCol>{prev?.id}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.nonInstrumentedBuildFileName.label')}</DataCol>
          <DataCol>{current.buildFileName}</DataCol>
          <DataCol>{prev?.buildFileName}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.name.label')}</DataCol>
          <DataCol>{current.name}</DataCol>
          <DataCol>{prev?.name}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.description.label')}</DataCol>
          <DataCol>{current.description}</DataCol>
          <DataCol>{prev?.description}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.sourceControlId.label')}</DataCol>
          <DataCol>{current.sourceControlId?.substring(0, 8)}</DataCol>
          <DataCol>{prev?.sourceControlId?.substring(0, 8)}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.sourceControlIsoTimestamp.label')}</DataCol>
          <DataCol>{prettifyDate(current.sourceControlIsoTimestamp)}</DataCol>
          <DataCol>{prettifyDate(prev?.sourceControlIsoTimestamp)}</DataCol>

          <DataCol isDescription>{t('ra.flow.build.uploadDate.label')}</DataCol>
          <DataCol>{prettifyDate(current.dateCreated)}</DataCol>
          <DataCol>{prettifyDate(prev?.dateCreated)}</DataCol>
        </div>
      </div>
    </div>
  )
}
