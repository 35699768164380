import React from 'react'
import { DashboardRunDto } from 'api/__generated__/api-types'
import { useTranslation } from 'react-i18next'
import { RaMvpStore } from 'components/regression-analysis-mvp/RaMvpStore'
import { Link } from 'react-router-dom'

interface InstrumentedResultsProps {
  raStore: RaMvpStore
  run: DashboardRunDto
}

export const InstrumentedResults = ({ raStore, run }: InstrumentedResultsProps) => {
  const { t } = useTranslation()
  return (
    <div>
      <p className="space-x-1">
        <span>{t('ra.flow.instrumentedResults.title')}</span>
      </p>
      <p className="space-x-1">
        <span>{t('ra.flow.build.instrumentedBuildFileName.label')}:</span>
        <span>{run.results.current.instrumented?.build.buildFileName}</span>
      </p>
      <div className="space-x-1">
        <span>{t('ra.flow.instrumentedResults.recordedTraces')}:</span>
        {run.results.current.instrumented?.traceIds?.map((traceId) => {
          const path = raStore.generateTracePath(traceId)
          return (
            <Link key={traceId} className="text-electro" to={path}>
              {traceId}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
