import { useEffect, useMemo, useState } from 'react'

import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { FlowDto, ProjectDto } from 'api/models'
import { useApi } from 'contexts/di-context'

import { RaMvpStore } from './RaMvpStore'

export interface RaMvpResponse {
  raStore: RaMvpStore | null
  isRaLoaded: boolean
  isRaFlow: boolean
  isRaEnabled: boolean
  isRaError: boolean
  // eslint-disable-next-line
  raErrorReason: any
}

export const useRaMvpData = (project?: ProjectDto, flow?: FlowDto): RaMvpResponse => {
  const api = useApi()
  const featureFlagEnabled = useFeatureFlag(FEATURE_FLAGS.REGRESSION_ANALYSIS_MVP)
  const [isRaLoaded, setIsRaLoaded] = useState(false)
  const [isRaEnabled, setIsRaEnabled] = useState(false)
  const [isRaFlow, setIsRaFlow] = useState(false)
  const [isRaError, setIsRaError] = useState(false)
  // eslint-disable-next-line
  const [raErrorReason, setRaErrorReason] = useState<any>()

  useEffect(() => {
    setIsRaFlow(flow?.automationConfig?.supportsRa ?? false)
  }, [flow])

  useEffect(() => setIsRaEnabled(featureFlagEnabled ?? false), [featureFlagEnabled])

  const raStore = useMemo(() => {
    if (project && flow && isRaEnabled) {
      return new RaMvpStore(api, project.id, project.urlName, flow.projectLocalId)
    } else {
      return null
    }
  }, [project, flow, isRaEnabled, api])

  useEffect(() => {
    if (featureFlagEnabled !== undefined) {
      if (featureFlagEnabled) {
        raStore
          ?.loadInitial()
          .then(() => {
            setIsRaLoaded(true)
          })
          .catch((reason) => {
            setIsRaError(true)
            setRaErrorReason(reason)
          })
      } else {
        setIsRaLoaded(true)
      }
    }
  }, [featureFlagEnabled, raStore])

  return {
    raStore,
    isRaEnabled: isRaLoaded && (isRaEnabled ?? false),
    isRaFlow,
    isRaLoaded,
    isRaError,
    raErrorReason,
  }
}
