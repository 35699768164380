module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx}'],
  future: {
    hoverOnlyWhenSupported: true,
  },
  theme: {
    colors: require('./src/utils/styles/colors').colors,
    extend: {
      transitionProperty: {
        height: 'height',
        spacing: 'margin, padding',
      },
      zIndex: {
        header: '99',
        modal: '999',
        progress: '9999',
      },
      gridTemplateColumns: {
        'flows-head': 'repeat(3, 1fr)',
        'project-users': '320px minmax(166px, 306px) minmax(130px, 270px) 50px',
      },
      height: {
        'teams-list': 'calc(100vh - var(--topBarHeight))',
        hints: 'calc(100vh - var(--topBarHeight) - var(--bottomBarHeight))',
        'details-panel':
          'calc(100vh - var(--topBarHeight) - var(--bottomBarHeight) - var(--tabHeaderHeight))',
        annotationsVideo: 'calc(100vh - 320px)',
        panelVideo: 'calc(100vh - 200px)',
        guideStepHeader: '74px',
        raDashboardLineHeight: '110px',
      },
      width: {
        guideStepWidth: '628px',
        guideStepContentWrapper: '725px',
      },
      spacing: {
        flowLeftPadding: '93px',
        flowRightPadding: '16px',
      },
      transitionDuration: {
        '50': '50ms',
        '100': '100ms',
        '250': '250ms',
      },
      lineHeight: {
        tighter: '1.1em',
        't-big-accent': '22px',
        't-small-accent': '17px',
        't-normal': '20px',
      },
      fontFamily: {
        inter: ['Inter', 'ui-sans-serif', 'system-ui'],
        'turret-road': ['Turret Road', 'ui-sans-serif', 'system-ui'],
        default: ['Manrope', 'ui-sans-serif', 'system-ui'],
      },
    },
    screens: {
      sm: '480px',
      md: '768px',
      lg: '1000px',
      'down-1280': { max: '1280px' },
      1280: '1280px',
      1440: '1440px',
      'down-1680': { max: '1680px' },
      1680: '1680px',
      'down-1920': { max: '1920px' },
      1920: '1920px',
    },
    fontSize: {
      micro: ['10px', '14px'],
      mini: ['11px', '15px'],
      small: ['12px', '19px'],
      normal: ['14px', '22px'],
      'normal-v2': ['14px', '21px'],
      big: ['16px', '26px'],
      'header-tiny': ['18px', '18px'],
      'header-small': ['22px', '33px'],
      'header-small-v2': ['20px', '30px'],
      'header-normal': ['28px', '28px'],
      'header-normal-v2': ['26px', '39px'],
      'header-big': ['34px', '34px'],
      icon: ['32px', '1'],
      'icon-small': ['16px', '1'],
    },
    letterSpacing: {
      tighter: '-.04em',
      tight: '-.01em',
      normal: '0',
      wide: '.02em',
      widest: '.04em',
      't-small': '0.03em',
    },
    boxShadow: {
      base: '0px 4px 8px rgba(0, 0, 0, 0.2)',
      none: '0 0 #0000',
    },
  },
  plugins: [require('@tailwindcss/line-clamp'), require('@tailwindcss/forms')],
}
