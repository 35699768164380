/* eslint-disable no-console */
import React, { memo, useRef, useEffect, useState, useCallback } from 'react'
import { Input } from 'components/Input'
import { ArkanoidHeader } from './ArkanoidHeader'

export const PATH_ARKANOID_GAME = '/arkanoid'

interface BrickInfo {
  x: number
  status: number
  isSpecial: boolean
  width: number // Added width property for randomized bricks
}

type Props = {
  name: string
  bestScore: number
  onGameOver?: (score: number) => void
}

export const ArkanoidGame = ({ onGameOver, name, bestScore }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const requestRef = useRef<number>()

  const [showConfig, setShowConfig] = useState(false)

  const [canvasWidth, setCanvasWidth] = useState(800)
  const [canvasHeight, setCanvasHeight] = useState(600)

  useEffect(() => {
    const handleResize = () => {
      setCanvasWidth(window.innerWidth)
      setCanvasHeight(window.innerHeight)
      setGameStatus('NewGame')
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const [gameStatus, setGameStatus] = useState<
    'NewGame' | 'Active' | 'NextLevel' | 'Respawn' | 'GameOver' | 'Preparing' | 'Paused'
  >('Preparing') // NewGame, NextLevel, Respawn, Active, GameOver

  const [initialLives, setInitialLives] = useState(3)
  const [lives, setLives] = useState(initialLives)
  const [level, setLevel] = useState(1)
  const [levelSpeedIncrease, setLevelSpeedIncrease] = useState(20)

  const [score, setScore] = useState(0)
  const [comboMultiplier, setComboMultiplier] = useState(1)
  const [rewardPoints, setRewardPoints] = useState(200)
  const [demotePoints, setDemotePoints] = useState(100)

  const updateScore = useCallback((points) => {
    setScore((prevScore) => Math.max(0, prevScore + points))
  }, [])

  const [isColorActive, setIsColorActive] = useState(false)
  const [specialAbilityTimeout, setSpecialAbilityTimeout] = useState(1000)

  const brickOffsetTop = 208
  const brickOffsetLeft = 0

  const [brickRowCount, setBrickRowCount] = useState(7)
  const [brickColumnCount, setBrickColumnCount] = useState(7)
  const [brickHeight, setBrickHeight] = useState(32)
  const [brickPadding, setBrickPadding] = useState(2)

  const [paddleWidth, setPaddleWidth] = useState(400)
  const [paddleHeight, setPaddleHeight] = useState(32)

  const [isBallActive, setIsBallActive] = useState(false)
  const [ballPosition, setBallPosition] = useState({
    x: canvasWidth / 2,
    y: canvasHeight - 100,
  })
  const ballDirection = useRef({ x: 2, y: 2 })
  const [ballRadius, setBallRadius] = useState(12)
  const [baseBallSpeed, setBaseBallSpeed] = useState(3.3)
  const [bounceAngleTheta, setBounceAngleTheta] = useState(4)
  const [ballSpeed, setBallSpeed] = useState(baseBallSpeed)
  //const maxSpeedIncrease = 1.35

  const updateBallSpeedByLevel = useCallback(
    (currentLevel: number) => {
      setBallSpeed(baseBallSpeed + baseBallSpeed * (currentLevel - 1) * (levelSpeedIncrease / 100))
    },
    [baseBallSpeed, levelSpeedIncrease],
  )

  const [paddlePosition, setPaddlePosition] = useState(350)
  const lastPaddlePosition = useRef(350)
  const lastMouseTime = useRef(Date.now())

  const [blueHitCounter, setBlueHitCounter] = useState(0)
  const [maxBlueHitCounter, setMaxBlueHitCounter] = useState(3)

  const [pill, setPill] = useState({ x: 0, y: 0, active: false })
  const [pillWidth, setPillWidth] = useState(48)

  const [pillsNum, setPillsNum] = useState(3)

  const [pillRespawnDeltaTimeout, setPillRespawnDeltaTimeout] = useState(0)
  const [pillRespawnMinTimeout, setPillRespawnMinTimeout] = useState(10000)

  const pillTimer = useRef<NodeJS.Timeout | null>(null)
  const specialAbilityTimer = useRef<NodeJS.Timeout | null>(null)

  const bricks = useRef<BrickInfo[][]>([])

  const setInitialBallPosition = useCallback(() => {
    const initialPaddleCenterX = paddlePosition + paddleWidth / 2
    const initialBallAbovePaddleY = canvasHeight - paddleHeight - ballRadius - 20

    setBallPosition({ x: initialPaddleCenterX, y: initialBallAbovePaddleY })

    updateBallSpeedByLevel(level)
    setIsBallActive(true)
  }, [
    paddlePosition,
    paddleWidth,
    canvasHeight,
    paddleHeight,
    ballRadius,
    updateBallSpeedByLevel,
    level,
  ])

  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (canvasRef.current === null) {
      return
    }
    const rect = canvasRef.current.getBoundingClientRect()
    let posX = event.clientX - rect.left
    posX = Math.max(posX, paddleWidth / 2)
    posX = Math.min(posX, canvasRef.current.width - paddleWidth / 2)
    setPaddlePosition(posX - paddleWidth / 2)
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === ' ') {
        console.log('hey pillsNum', pillsNum)
        if (pillsNum > 0 && !isColorActive) {
          // Activate special ability for 10 seconds
          setIsColorActive(true)
          if (specialAbilityTimer.current) {
            clearTimeout(specialAbilityTimer.current)
          }
          specialAbilityTimer.current = setTimeout(() => {
            setIsColorActive(false)
          }, specialAbilityTimeout)
          setPillsNum((n) => n - 1)
        }
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [specialAbilityTimeout, isColorActive, pillsNum])

  const maxRows = useCallback(
    () => Math.floor((canvasHeight - paddleHeight * 6) / (brickHeight + brickPadding)),
    [brickHeight, brickPadding, canvasHeight, paddleHeight],
  )

  const handleGameOver = useCallback(() => {
    setIsBallActive(false)
    if (lives > 1) {
      setLives(lives - 1)
      setGameStatus('Respawn')
    } else {
      setGameStatus('GameOver')
      setLives(3) // Reset lives for the next game
      if (onGameOver) {
        onGameOver(score)
      }
    }
  }, [score, lives, onGameOver])

  const createBricks = useCallback(
    (currentLevel) => {
      const initBricks: BrickInfo[][] = []
      const minBrickWidth = canvasWidth / brickColumnCount / 2
      const maxBrickWidth = (canvasWidth / brickColumnCount) * 4
      const totalRows = Math.min(brickRowCount + currentLevel - 1, maxRows())

      for (let r = 0; r < totalRows; r++) {
        initBricks[r] = []
        const rowWidths = []
        let totalRowWidth = 0

        // Randomize widths and find the largest brick
        for (let c = 0; c < brickColumnCount; c++) {
          const brickWidth = Math.random() * (maxBrickWidth - minBrickWidth) + minBrickWidth
          rowWidths.push(brickWidth)
          totalRowWidth += brickWidth
        }

        // Scale widths to fit the canvas width
        const scalingFactor = (canvasWidth - brickPadding * (brickColumnCount - 1)) / totalRowWidth
        let currentX = brickOffsetLeft
        let maxBrickIndex = 0

        for (let c = 0; c < brickColumnCount; c++) {
          rowWidths[c] *= scalingFactor
          if (rowWidths[c] > rowWidths[maxBrickIndex]) {
            maxBrickIndex = c
          }
        }

        // Assign bricks with adjusted widths
        for (let c = 0; c < brickColumnCount; c++) {
          const brickWidth = rowWidths[c]
          const brickX = currentX
          const isSpecialBrick = c === maxBrickIndex

          initBricks[r][c] = {
            x: brickX,
            status: 1,
            isSpecial: isSpecialBrick,
            width: brickWidth,
          }
          currentX += brickWidth + brickPadding
        }
      }
      bricks.current = initBricks
    },
    [brickColumnCount, brickPadding, brickRowCount, canvasWidth, maxRows],
  )

  const checkLevelCompletion = useCallback(() => {
    const allRowsDestroyed = bricks.current.length === 0
    return allRowsDestroyed
  }, [bricks])

  const schedulePillRespawn = useCallback(() => {
    const pillAppearanceDelay = Math.random() * pillRespawnDeltaTimeout + pillRespawnMinTimeout

    if (pillTimer.current) {
      clearTimeout(pillTimer.current)
    }

    pillTimer.current = setTimeout(() => {
      setPill({
        x: Math.random() * (canvasWidth - pillWidth),
        y: bricks.current.length * (brickHeight + brickPadding) + brickOffsetTop,
        active: true,
      })
    }, pillAppearanceDelay)
  }, [
    pillRespawnDeltaTimeout,
    pillRespawnMinTimeout,
    canvasWidth,
    pillWidth,
    brickHeight,
    brickPadding,
  ])

  const handlePillRespawn = useCallback(() => {
    setPill((prevPill) => {
      if (prevPill.active) {
        schedulePillRespawn() // Schedule a new pill only if the previous pill was active
      }
      return { x: 0, y: 0, active: false }
    })
  }, [schedulePillRespawn])

  const generateNewRowOfBricks = useCallback(() => {
    const newRow = []
    const minBrickWidth = canvasWidth / brickColumnCount / 2
    const maxBrickWidth = (canvasWidth / brickColumnCount) * 4
    const rowWidths = []
    let totalRowWidth = 0

    // Randomize widths
    for (let c = 0; c < brickColumnCount; c++) {
      const brickWidth = Math.random() * (maxBrickWidth - minBrickWidth) + minBrickWidth
      rowWidths.push(brickWidth)
      totalRowWidth += brickWidth
    }

    // Scale widths to fit the canvas width
    const scalingFactor = (canvasWidth - brickPadding * (brickColumnCount - 1)) / totalRowWidth
    let currentX = brickOffsetLeft
    let maxBrickIndex = 0

    for (let c = 0; c < brickColumnCount; c++) {
      rowWidths[c] *= scalingFactor
      if (rowWidths[c] > rowWidths[maxBrickIndex]) {
        maxBrickIndex = c
      }
    }

    // Assign bricks with adjusted widths
    for (let c = 0; c < brickColumnCount; c++) {
      const brickWidth = rowWidths[c]
      const brickX = currentX
      const isSpecialBrick = c === maxBrickIndex

      newRow.push({ x: brickX, status: 1, isSpecial: isSpecialBrick, width: brickWidth })
      currentX += brickWidth + brickPadding
    }

    return newRow
  }, [brickColumnCount, brickPadding, canvasWidth])

  const handleBrickHit = useCallback(
    (brick) => {
      if (brick.isSpecial) {
        // Red brick hit
        bricks.current.splice(bricks.current.length - 1, 1) // Remove the entire row
        setBlueHitCounter(0)
        updateScore(rewardPoints * comboMultiplier)
        setComboMultiplier((prev) => prev + 1) // Increase combo for consecutive red brick hits
      } else {
        // Blue brick hit
        setBlueHitCounter((c) => c + 1)
        if (level > 1) {
          updateScore(-demotePoints * 0.025 * (level - 1)) // Penalty for hitting blue bricks, increasing with level
        }
        setComboMultiplier(1) // Reset combo multiplier on blue brick hit
      }

      if (blueHitCounter >= maxBlueHitCounter) {
        updateScore(-demotePoints) // Penalty for adding a new row
        setBlueHitCounter(0)
        // If user have too many rows - give game over as penalty
        if (bricks.current.length >= maxRows()) {
          setGameStatus('GameOver')
        } else {
          setTimeout(() => {
            const newRow = generateNewRowOfBricks()
            bricks.current.unshift(newRow)
          }, 500)
        }
      }
    },
    [
      maxBlueHitCounter,
      updateScore,
      rewardPoints,
      comboMultiplier,
      level,
      demotePoints,
      maxRows,
      generateNewRowOfBricks,
      blueHitCounter,
    ],
  )

  const collisionBricksDetection = useCallback(() => {
    for (let r = 0; r < bricks.current.length; r++) {
      const brickY = r * (brickHeight + brickPadding) + brickOffsetTop
      for (let c = 0; c < brickColumnCount; c++) {
        const b = bricks.current[r][c]
        if (b && b.status === 1) {
          // Extend collision detection area
          const extendedBrickX = b.x - ballRadius
          const extendedBrickRightX = b.x + b.width + ballRadius
          const extendedBrickY = brickY - ballRadius
          const extendedBrickBottomY = brickY + brickHeight + ballRadius

          const ballNextX = ballPosition.x + ballDirection.current.x * ballSpeed
          const ballNextY = ballPosition.y + ballDirection.current.y * ballSpeed

          // Check collisions with extended areas
          const hitLeft =
            ballNextX > extendedBrickX &&
            ballNextX < b.x &&
            ballNextY > extendedBrickY &&
            ballNextY < extendedBrickBottomY
          const hitRight =
            ballNextX < extendedBrickRightX &&
            ballNextX > b.x + b.width &&
            ballNextY > extendedBrickY &&
            ballNextY < extendedBrickBottomY
          const hitTop =
            ballNextY > extendedBrickY &&
            ballNextY < brickY &&
            ballNextX > extendedBrickX &&
            ballNextX < extendedBrickRightX
          const hitBottom =
            ballNextY < extendedBrickBottomY &&
            ballNextY > brickY + brickHeight &&
            ballNextX > extendedBrickX &&
            ballNextX < extendedBrickRightX

          if (hitLeft || hitRight || hitTop || hitBottom) {
            if (hitLeft || hitRight) {
              ballDirection.current.x = -ballDirection.current.x
            }
            if (hitTop || hitBottom) {
              ballDirection.current.y = -ballDirection.current.y
            }

            // Normalize the ball direction
            const directionLength = Math.sqrt(
              ballDirection.current.x * ballDirection.current.x +
                ballDirection.current.y * ballDirection.current.y,
            )
            ballDirection.current.x /= directionLength
            ballDirection.current.y /= directionLength

            // Collision response logic
            handleBrickHit(b)
            return
          }
        }
      }
    }
  }, [
    ballPosition,
    ballRadius,
    ballSpeed,
    brickColumnCount,
    brickHeight,
    brickPadding,
    handleBrickHit,
  ])

  const collisionPaddleDetection = useCallback(() => {
    const currentTime = Date.now()
    lastMouseTime.current = currentTime
    lastPaddlePosition.current = paddlePosition

    if (canvasRef.current === null) {
      return
    }

    const maxBounceAngle = Math.PI / bounceAngleTheta // Maximum bounce angle
    const paddleTopY = canvasRef.current.height - paddleHeight
    const paddleLeftX = paddlePosition
    const paddleRightX = paddlePosition + paddleWidth

    const ballNextX = ballPosition.x + ballDirection.current.x * ballSpeed
    const ballNextY = ballPosition.y + ballDirection.current.y * ballSpeed

    const withinPaddleHorizontalBounds = ballNextX > paddleLeftX && ballNextX < paddleRightX
    const approachingPaddleTop =
      ballNextY + ballRadius >= paddleTopY && ballNextY - ballRadius < canvasRef.current.height

    const hitPaddleLeftCorner =
      ballNextX + ballRadius > paddleLeftX && ballNextX < paddleLeftX && ballNextY > paddleTopY

    const hitPaddleRightCorner =
      ballNextX - ballRadius < paddleRightX && ballNextX > paddleRightX && ballNextY > paddleTopY

    if (withinPaddleHorizontalBounds && approachingPaddleTop) {
      const relativePosition = (ballNextX - (paddlePosition + paddleWidth / 2)) / (paddleWidth / 2)
      const bounceAngle = relativePosition * maxBounceAngle

      // Calculate new direction
      const newDirectionX = Math.sin(bounceAngle)
      const newDirectionY = -Math.cos(bounceAngle)

      // Normalize the direction vector
      const length = Math.sqrt(newDirectionX * newDirectionX + newDirectionY * newDirectionY)
      ballDirection.current.x = newDirectionX / length
      ballDirection.current.y = newDirectionY / length

      // Reposition to prevent sticking
      const newY = paddleTopY - ballRadius
      setBallPosition({ x: ballPosition.x, y: newY })
    } else if (hitPaddleLeftCorner || hitPaddleRightCorner) {
      ballDirection.current.x = -ballDirection.current.x
      // Optional: Additional logic for Y direction if hitting the corner
    }
  }, [
    paddlePosition,
    bounceAngleTheta,
    paddleHeight,
    paddleWidth,
    ballPosition.x,
    ballPosition.y,
    ballSpeed,
    ballRadius,
  ])

  const collisionBoundaryDetection = useCallback(() => {
    if (canvasRef.current === null) {
      return
    }

    let newPositionX = ballPosition.x
    let newPositionY = ballPosition.y

    // Left Boundary
    if (newPositionX < ballRadius) {
      ballDirection.current.x = Math.abs(ballDirection.current.x) // Ensure positive direction
      newPositionX = ballRadius // Adjust position
    }

    // Right Boundary
    if (newPositionX + ballRadius > canvasRef.current.width) {
      ballDirection.current.x = -Math.abs(ballDirection.current.x) // Ensure negative direction
      newPositionX = canvasRef.current.width - ballRadius // Adjust position
    }

    // Top Boundary
    if (newPositionY < ballRadius) {
      ballDirection.current.y = Math.abs(ballDirection.current.y) // Ensure positive direction
      newPositionY = ballRadius // Adjust position
    }

    // Bottom Boundary - Game Over
    if (newPositionY + ballRadius > canvasRef.current.height && isBallActive) {
      handleGameOver()
    } else {
      setBallPosition({ x: newPositionX, y: newPositionY }) // Update position
    }
  }, [ballPosition, ballRadius, handleGameOver, isBallActive])

  const catchPillDetection = useCallback(() => {
    if (canvasRef.current === null) {
      return
    }
    if (
      pill.y + pillWidth >= canvasRef.current.height - paddleHeight &&
      pill.x >= paddlePosition &&
      pill.x <= paddlePosition + paddleWidth &&
      pill.active
    ) {
      setPillsNum((n) => n + 1)
      handlePillRespawn()
    }
  }, [pill, pillWidth, paddleHeight, paddlePosition, paddleWidth, handlePillRespawn])

  const drawBricks = useCallback(
    (ctx: CanvasRenderingContext2D) => {
      for (let r = 0; r < bricks.current.length; r++) {
        for (let c = 0; c < bricks.current[r].length; c++) {
          const b = bricks.current[r][c]
          if (b.status === 1) {
            const brickY = r * (brickHeight + brickPadding) + brickOffsetTop
            ctx.beginPath()
            ctx.rect(b.x, brickY, b.width, brickHeight)
            ctx.fillStyle = isColorActive ? (b.isSpecial ? '#C3465C' : '#5F41B4') : 'gray'
            ctx.fill()
            ctx.closePath()
          }
        }
      }
    },
    [brickHeight, brickPadding, isColorActive],
  )

  const drawBall = useCallback(
    (ctx: CanvasRenderingContext2D, radius: number) => {
      ctx.beginPath()
      ctx.arc(ballPosition.x, ballPosition.y, radius, 0, Math.PI * 2)
      ctx.fillStyle = '#FFFFFF'
      ctx.fill()
      ctx.closePath()
    },
    [ballPosition],
  )

  const drawPaddle = useCallback(
    (ctx: CanvasRenderingContext2D, position: number, width: number, height: number) => {
      // Paddle background
      ctx.fillStyle = '#472B98'
      ctx.fillRect(position, canvasHeight - height, width, height)

      // Draw the trapezoid inside the paddle
      const trapezoidTopWidth = width * 0.1 // This controls the top width of the trapezoid, adjust as needed
      const trapezoidBottomWidth = width // The bottom width of the trapezoid is the same as the paddle

      // Calculate the starting X position for the trapezoid top edge
      const trapezoidTopStart = position + (width - trapezoidTopWidth) / 2

      // Draw the trapezoid
      ctx.beginPath()
      ctx.moveTo(trapezoidTopStart, canvasHeight - height) // Top left corner of trapezoid
      ctx.lineTo(trapezoidTopStart + trapezoidTopWidth, canvasHeight - height) // Top right corner
      ctx.lineTo(position + trapezoidBottomWidth, canvasHeight) // Bottom right corner
      ctx.lineTo(position, canvasHeight) // Bottom left corner
      ctx.closePath()

      ctx.fillStyle = '#6C4ACE'
      ctx.fill()
    },
    [canvasHeight],
  )

  const moveBall = useCallback(() => {
    if (!isBallActive) {
      return
    }
    // Normalize the ball direction
    const directionLength = Math.sqrt(
      ballDirection.current.x * ballDirection.current.x +
        ballDirection.current.y * ballDirection.current.y,
    )

    const normalizedDirectionX = ballDirection.current.x / directionLength
    const normalizedDirectionY = ballDirection.current.y / directionLength

    // Move the ball based on the normalized direction and the speed
    const newX = ballPosition.x + normalizedDirectionX * ballSpeed
    const newY = ballPosition.y + normalizedDirectionY * ballSpeed

    setBallPosition({ x: newX, y: newY })
  }, [ballPosition.x, ballPosition.y, ballSpeed, isBallActive])

  const movePill = useCallback(() => {
    if (pill.active) {
      // Calculate new Y position for the pill
      const newY = pill.y + 1

      // Update pill's position without directly affecting ball movement or collision detection
      setPill((prevPill) => ({ ...prevPill, y: newY }))

      // Check if the pill has moved off the canvas
      if (newY >= canvasHeight) {
        handlePillRespawn()
      }
    }
  }, [pill, canvasHeight, handlePillRespawn])

  const updateGame = useCallback(() => {
    if (canvasRef.current === null) {
      return
    }

    if (
      gameStatus === 'GameOver' ||
      gameStatus === 'Preparing' ||
      gameStatus === 'Paused' ||
      showConfig
    ) {
      return
    }

    if (gameStatus === 'Respawn') {
      console.log('updateGame Respawn')
      setInitialBallPosition()
      schedulePillRespawn()
      setGameStatus('Active')
      return // Stop the game loop in GameOver state
    }

    if (checkLevelCompletion()) {
      setGameStatus('NextLevel')
    }

    requestRef.current = requestAnimationFrame(updateGame)

    const ctx = canvasRef.current.getContext('2d')
    if (ctx === null) {
      return
    }
    ctx.clearRect(0, 0, canvasWidth, canvasRef.current.height)

    drawBricks(ctx)
    drawBall(ctx, ballRadius)
    drawPaddle(ctx, paddlePosition, paddleWidth, paddleHeight)

    collisionBoundaryDetection()
    collisionBricksDetection()
    collisionPaddleDetection()

    catchPillDetection()

    if (isBallActive) {
      moveBall()
    }
    if (pill.active) {
      movePill()
    }
  }, [
    ballRadius,
    canvasWidth,
    catchPillDetection,
    checkLevelCompletion,
    collisionBoundaryDetection,
    collisionBricksDetection,
    collisionPaddleDetection,
    drawBall,
    drawBricks,
    drawPaddle,
    gameStatus,
    isBallActive,
    moveBall,
    movePill,
    paddleHeight,
    paddlePosition,
    paddleWidth,
    pill.active,
    schedulePillRespawn,
    setInitialBallPosition,
    showConfig,
  ])

  useEffect(() => {
    console.log('gameStatus Changed', gameStatus)
    if (gameStatus === 'GameOver') {
      console.log('gameStatus Changed to GameOver - Click to start New Game')
    }
    if (gameStatus === 'Preparing') {
    }
    if (gameStatus === 'NewGame') {
      console.log('gameStatus Changed from NewGame to Respawn')
      setIsBallActive(false)
      createBricks(1) // Initialize the first level
      setLives(initialLives) // Reset lives if needed
      setLevel(1) // Start from level 1
      setGameStatus('Respawn') // Move to Respawn or Active as needed
    }
    if (gameStatus === 'NextLevel') {
      console.log('gameStatus Changed from NextLevel to Respawn')
      setIsBallActive(false)
      setLevel((prevLevel) => {
        const nextLevel = prevLevel + 1
        updateScore(rewardPoints * nextLevel)
        createBricks(nextLevel)
        setGameStatus('Respawn')
        return nextLevel
      })
    }
  }, [baseBallSpeed, createBricks, gameStatus, initialLives, rewardPoints, updateScore])

  useEffect(() => {
    requestRef.current = requestAnimationFrame(updateGame)
    return () => {
      if (requestRef.current) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [paddlePosition, ballPosition, updateGame])

  useEffect(() => {
    const handleCanvasClick = () => {
      if (gameStatus === 'GameOver') {
        setGameStatus('NewGame')
      }
    }
    const canvas = canvasRef.current
    if (canvas !== null) {
      canvas.addEventListener('click', handleCanvasClick)
    }
    return () => {
      if (canvas !== null) {
        canvas.removeEventListener('click', handleCanvasClick)
      }
    }
  }, [gameStatus])

  return (
    <div className="flex-col w-full h-full m-auto bg-black">
      <ArkanoidHeader hasLogo />
      <div className="w-full font-turret-road pt-[80px] b-relative h-[208px]">
        <div className="pl-[24px] absolute left-0 top-[80px]">
          <h3 className="text-[64px] leading-[64px] font-bold">{name}</h3>
          <p className="text-[22px] text-gray-service pt-2">
            Lives <span className="text-white pr-5">{lives}</span>
            Level <span className="text-white">{level}</span>
          </p>
        </div>

        <div className="w-full text-center">
          <h3 className="text-[64px] leading-[64px] font-bold">
            Score <span className="text-[#D4FF5B]">{score}</span>
          </h3>
          <p className="text-[22px] text-gray-service pt-2">
            Best score <span className="text-white">{bestScore}</span>
          </p>
        </div>

        <div className="text-right text-[#D4FF5B] pr-[24px] absolute top-[80px] right-0">
          <div className="flex items-right justify-end text-[64px] leading-[64px] font-bold">
            <Pill />
            <span className="pl-1">&times;{pillsNum}</span>
          </div>
          <p className="text-[22px] text-gray-service pt-2">
            <span className="text-white">Press Space</span> to activate PS Tool
          </p>
        </div>
      </div>
      {pill.active && (
        <div
          className="absolute top-0 left-0"
          style={{ transform: `translate(${pill.x}px, ${pill.y}px)` }}
        >
          <Pill width={pillWidth} />
        </div>
      )}
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        onMouseMove={handleMouseMove}
        className="absolute left-0 top-0"
      />
      <div className="absolute right-0 bottom-0">
        {showConfig ? (
          <div className="flex-col w-[1000px] m-auto bg-black">
            <button onClick={() => setShowConfig(false)} className="absolute top-0 right-0 p-3">
              START
            </button>
            <div className="flex">
              <label>
                Paddle Width:
                <Input
                  inputMode="numeric"
                  value={paddleWidth}
                  onChange={(e) => setPaddleWidth(Number(e.target.value))}
                />
              </label>
              <label>
                Paddle Height:
                <Input
                  inputMode="numeric"
                  value={paddleHeight}
                  onChange={(e) => setPaddleHeight(Number(e.target.value))}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                Brick Row Count:
                <Input
                  inputMode="numeric"
                  value={brickRowCount}
                  onChange={(e) => setBrickRowCount(Number(e.target.value))}
                />
              </label>
              <label>
                Brick Column Count:
                <Input
                  inputMode="numeric"
                  value={brickColumnCount}
                  onChange={(e) => setBrickColumnCount(Number(e.target.value))}
                />
              </label>
              <label>
                Brick Height:
                <Input
                  inputMode="numeric"
                  value={brickHeight}
                  onChange={(e) => setBrickHeight(Number(e.target.value))}
                />
              </label>
              <label>
                Brick Padding:
                <Input
                  inputMode="numeric"
                  value={brickPadding}
                  onChange={(e) => setBrickPadding(Number(e.target.value))}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                Base Ball Speed:
                <Input
                  inputMode="numeric"
                  value={baseBallSpeed}
                  onChange={(e) => setBaseBallSpeed(Number(e.target.value))}
                />
              </label>
              <label>
                Ball Radius:
                <Input
                  inputMode="numeric"
                  value={ballRadius}
                  onChange={(e) => setBallRadius(Number(e.target.value))}
                />
              </label>
              <label>
                Max Blue Hit Counter:
                <Input
                  inputMode="numeric"
                  value={maxBlueHitCounter}
                  onChange={(e) => setMaxBlueHitCounter(Number(e.target.value))}
                />
              </label>
              <label>
                Ball Bounce Angle (Pi / ϴ):
                <Input
                  inputMode="numeric"
                  value={bounceAngleTheta}
                  onChange={(e) => setBounceAngleTheta(Number(e.target.value))}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                Pill Width:
                <Input
                  inputMode="numeric"
                  value={pillWidth}
                  onChange={(e) => setPillWidth(Number(e.target.value))}
                />
              </label>
              <label>
                Pill Min Respawn Timeout:
                <Input
                  inputMode="numeric"
                  value={pillRespawnMinTimeout}
                  onChange={(e) => setPillRespawnMinTimeout(Number(e.target.value))}
                />
              </label>
              <label>
                Pill Delta Respawn Timeout:
                <Input
                  inputMode="numeric"
                  value={pillRespawnDeltaTimeout}
                  onChange={(e) => setPillRespawnDeltaTimeout(Number(e.target.value))}
                />
              </label>
              <label>
                Special Ability Timeout:
                <Input
                  inputMode="numeric"
                  value={specialAbilityTimeout}
                  onChange={(e) => setSpecialAbilityTimeout(Number(e.target.value))}
                />
              </label>
            </div>
            <div className="flex">
              <label>
                Lives:
                <Input
                  inputMode="numeric"
                  value={initialLives}
                  onChange={(e) => {
                    setInitialLives(Number(e.target.value))
                    setLives(Number(e.target.value))
                  }}
                />
              </label>
              <label>
                RewardPoints:
                <Input
                  inputMode="numeric"
                  value={rewardPoints}
                  onChange={(e) => setRewardPoints(Number(e.target.value))}
                />
              </label>
              <label>
                DemotePoints:
                <Input
                  inputMode="numeric"
                  value={demotePoints}
                  onChange={(e) => setDemotePoints(Number(e.target.value))}
                />
              </label>
              <label>
                Speed per Level %:
                <Input
                  inputMode="numeric"
                  value={levelSpeedIncrease}
                  onChange={(e) => setLevelSpeedIncrease(Number(e.target.value))}
                />
              </label>
            </div>
          </div>
        ) : (
          <button onClick={() => setShowConfig(true)} className="p-3">
            ⚙️
          </button>
        )}
      </div>
    </div>
  )
}

const Pill = memo(({ width = 60, className }: { width?: number; className?: string }) => {
  return (
    <svg
      width={width}
      height={width}
      className={className}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="15.8824" fill="#D4FF5B" />
      <path
        d="M26.6117 17.2058H17.5764V23.3247H15.8823V31.6687H17.5764V42.794H44.1176V34.4501H26.6117V31.6687H44.1176V23.3247H26.6117V17.2058Z"
        fill="#0F0F0F"
      />
    </svg>
  )
})
