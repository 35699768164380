import React, { useEffect, useMemo } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import NProgress from 'nprogress'
import { Helmet } from 'react-helmet'
import { Layout } from 'components/Layout'
import { FlowHead } from 'components/flows/FlowHead'
import { useFlowQuery, useFlowTracesQuery, useProjectQuery, useUserQuery } from 'hooks/useApiQuery'
import { PATH_FLOWS } from 'pages/FlowsPage'
import { TeamRoleApi } from 'api/models'
import { TraceDtoType } from 'api/__generated__/api-constants'
import { FEATURE_FLAGS, useFeatureFlag } from 'utils/feature-flags'
import { FlowPageLiveDemoActions } from 'components/live-demo/FlowPageLiveDemoActions'
import { isUserProductScience } from 'utils/hiddenFlow'
import { useIoGuidesRedirect } from 'components/guide-io/useIoGuidesRedirect'
import { BaseHeader } from 'components/header/BaseHeader'
import { PageTitle } from 'components/header/PageTitle'
import { FlowTracesPageBreadcrumbs } from 'components/breadcrumbs/FlowTracesPageBreadcrumbs'
import { UpdateFilterButton } from 'pages/flow/UpdateFilterButton'
import { FlowTabs } from 'components/flows/FlowTabs'
import { TraceTable } from 'components/flows/TraceTable'
import { useRaMvpData } from 'components/regression-analysis-mvp/useRaMvpData'

export const PATH_FLOW_START = '/projects/:projectUrlName/flows/:flowProjectLocalId/'
export const PATH_FLOW_END = 'traces'
const RA_SETTINGS_KEY = 'ra-settings'
export const PATH_FLOW = PATH_FLOW_START + PATH_FLOW_END
export const PATH_FLOW_FULL_PATTERN = `${PATH_FLOW}/${RA_SETTINGS_KEY}?`

export const FlowPage = () => {
  const { projectUrlName, flowProjectLocalId } = useParams() as {
    projectUrlName: string
    flowProjectLocalId: string
  }

  const { isSuccess: isUserSuccess, data: user } = useUserQuery()
  const { isSuccess: isProjectSuccess, data: project } = useProjectQuery({ projectUrlName })
  const { isSuccess: isFlowSuccess, data: flowData } = useFlowQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  const {
    isSuccess: isFlowTracesSuccess,
    data: flowTracesData,
    refetch: refetchFlowsTraces,
  } = useFlowTracesQuery({
    projectUrlName,
    flowProjectLocalId,
  })
  useIoGuidesRedirect({ user, project })

  const { raStore, isRaLoaded, isRaEnabled, isRaFlow, isRaError, raErrorReason } = useRaMvpData(
    project,
    flowData,
  )

  useEffect(() => {
    if (isRaError) {
      throw new Error(raErrorReason)
    }
  }, [isRaError, raErrorReason])

  const isLoaded =
    isFlowSuccess && isFlowTracesSuccess && isUserSuccess && isProjectSuccess && isRaLoaded

  const isLiveDemoPerFlowEnabled = useFeatureFlag(FEATURE_FLAGS.LIVE_DEMO_PER_FLOW)

  const filteredTraces = useMemo(
    () => flowTracesData?.filter((trace) => trace.type === TraceDtoType.TRACE),
    [flowTracesData],
  )
  const liveDemoTrace = useMemo(
    () =>
      flowTracesData
        ?.filter((trace) => trace.type === TraceDtoType.LIVE_DEMO)
        .sort((a, b) => Number(a.dateCreated) - Number(b.dateCreated))[0],
    [flowTracesData],
  )

  const isSuperAdmin = user?.roles.isSuperAdmin ?? false
  const teamRole = user?.roles.teams[project?.team.id as number]
  const projectRole = user?.roles.projects[project?.id as number]
  const canWorkWithFlow = Boolean(
    !isSuperAdmin || (teamRole && teamRole !== TeamRoleApi.NONE) || projectRole,
  )

  const isInternalUser = user ? isUserProductScience(user.email) : false

  useEffect(() => {
    // This condition handles scenarios where the live demo was detached from the targeted flow and then user returned to the hidden flow.
    // In such cases, we need to trigger a data refetch.
    if (isInternalUser) {
      refetchFlowsTraces()
      const handleFocus = () => refetchFlowsTraces()
      window.addEventListener('focusin', handleFocus)
      return () => window.removeEventListener('focusin', handleFocus)
    }
  }, [isInternalUser, refetchFlowsTraces])

  useEffect(() => {
    if (isLoaded) {
      NProgress.done()
    } else {
      NProgress.start()
    }
  }, [isLoaded])

  const header = useMemo(
    () => (
      <BaseHeader
        leftSideContent={<FlowTracesPageBreadcrumbs />}
        centerContent={<PageTitle titleKey="library" />}
      />
    ),
    [],
  )

  return (
    <Layout headerComponent={header}>
      {isLoaded && (
        <>
          {flowData && (
            <>
              <Helmet>
                <title>{flowData.name}</title>
              </Helmet>
              <FlowHead
                {...flowData}
                backLink={generatePath(PATH_FLOWS, { projectUrlName })}
                showMyTracesOnlyButton={
                  !!filteredTraces?.find((trace) => trace?.createdBy === user?.name)
                }
                canWorkWithFlow={canWorkWithFlow}
              />
            </>
          )}
          {flowData?.hidden && (
            <UpdateFilterButton
              flowProjectLocalId={flowProjectLocalId}
              projectUrlName={projectUrlName}
            />
          )}
          {isLiveDemoPerFlowEnabled && liveDemoTrace && (
            <FlowPageLiveDemoActions
              refetch={refetchFlowsTraces}
              trace={liveDemoTrace}
              flowProjectLocalId={flowProjectLocalId}
              projectUrlName={projectUrlName}
            />
          )}
          {(!isRaEnabled || !isRaFlow) && (
            <TraceTable
              flowTracesData={flowTracesData}
              canWorkWithFlow={canWorkWithFlow}
              projectUrlName={project!.urlName}
              flowProjectLocalId={flowData!.projectLocalId}
              isHiddenFlow={flowData!.hidden ?? undefined}
            />
          )}
          {isRaEnabled && isRaFlow && (
            <FlowTabs
              flowTracesData={filteredTraces}
              canWorkWithFlow={canWorkWithFlow}
              project={project!}
              flowDto={flowData!}
              raStore={raStore!}
              isAdmin={isSuperAdmin || isInternalUser}
            />
          )}
        </>
      )}
    </Layout>
  )
}
