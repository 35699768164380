import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom'

import { Root } from 'components/Root'
import { RequireAuth } from 'components/auth/RequireAuth'
import { SkipAuth } from 'components/auth/SkipAuth'

import { FEATURE_FLAGS, FeatureFlagRoute } from 'utils/feature-flags'

import { PATH_SIGN_IN, SignInPage } from 'pages/auth/SignInPage'
import { InviteWhitelistPage, PATH_INVITE_WHITELIST } from 'pages/auth/InviteWhitelistPage'
import { PATH_SIGN_UP, SignUpPage } from 'pages/auth/SignUpPage'
import { PATH_SIGN_UP_SSO, SignUpSsoPage } from 'pages/auth/SignUpSsoPage'
import { PATH_SIGN_UP_TRIAL, SignUpTrialPage } from 'pages/auth/SignUpTrialPage'
import {
  PATH_SIGN_UP_EARLY_BIRD,
  PATH_SIGN_UP_BETA,
  SignUpEarlyBirdPage,
} from 'pages/auth/SignUpEarlyBirdPage'
import { PATH_RECOVERY_TOKEN, RecoveryTokenPage } from 'pages/auth/RecoveryTokenPage'
import { PATH_RESET_PASSWORD, ResetPasswordPage } from 'pages/auth/ResetPasswordPage'

import { MainPage } from 'pages/MainPage'
import { PATH_TEAM, PATH_TEAM_START, TeamPage } from 'pages/TeamPage'
import { PATH_TEAM_CONTRIBUTORS, TeamContributorsPage } from 'pages/TeamContributorsPage'
import { PATH_PROJECT_CONTRIBUTORS, ProjectContributorsPage } from 'pages/ProjectContributorsPage'
import {
  FlowsPage,
  PATH_FLOWS,
  PATH_FLOWS_END,
  PATH_FLOWS_START,
  PATH_PROJECTS,
} from 'pages/FlowsPage'
import { PATH_UNASSIGNED, UnassignedPage } from 'pages/UnassignedPage'
import { FlowPage, PATH_FLOW_END, PATH_FLOW_FULL_PATTERN, PATH_FLOW_START } from 'pages/FlowPage'
import { PATH_TRACE_ADD, TraceAddPage } from 'pages/TraceAddPage'
import { PATH_ANNOTATIONS_FOLDER, PsAnnotationsPage } from 'pages/AnnotationsPage'
import { PATH_CHART_ROUTER, PsChartRouter } from 'pages/PsChartRouter'
import { PATH_CHART_DIRECT, PATH_CHART_FOLDER, PsChartPage } from 'pages/PsChartPage'
import { PATH_PROCESSES_FOLDER, PxAPage } from 'pages/PxAPage'
import { STATIC_PATH_CHART, StaticPsChartPage } from 'pages/StaticPage'

import { ErrorPage } from 'pages/ErrorPage'
import { ErrorPageAuthorized } from 'pages/ErrorPageAuthorized'
import { ChartRootPage } from 'pages/ChartRootPage'
import {
  FreeTrialQuestionnairePage,
  PATH_FREE_TRIAL_QUESTIONNAIRE,
} from 'pages/FreeTrialQuestionnairePage'
import {
  GuidePage,
  PATH_GUIDE_INSTRUMENT_AND_BUILD,
  PATH_GUIDE_RECORD_TRACE,
  PATH_GUIDE_ROUTER,
  PATH_GUIDE_SELECT_PLATFORM,
  PATH_GUIDE_VIEW_TRACE,
} from 'pages/guide/GuideRootPage'

import { GuideSelectPlatform } from 'pages/guide/GuideSelectPlatform'
import { InstrumentAndBuild } from 'pages/guide/InstrumentAndBuild'
import { RecordTrace } from 'pages/guide/RecordTrace'
import { ViewTrace } from 'pages/guide/ViewTrace'
import { MD_TO_HTML_PATH, MdToHtmlPage } from 'pages/MdToHtmlPage'
import { CONTENTFUL_DEMO_PATH, ContentfulDemoPage } from 'pages/ContentfulDemoPage'
import {
  QUICK_START_PROTOTYPE_PAGE_PATH,
  QuickStartPrototypePage,
} from 'pages/QuickStartPrototypePage'
import { EXPIRED_TRIAL_PATH, ExpiredTrialPage } from 'pages/ExpiredTrialPage'
import { VIDEO_NAVIGATION_PATH, VideoNavPage } from 'pages/VideoNavigationPage'
import { isHostTypeProd } from 'utils/getHostType'
import { LiveDemoPage, PATH_LIVE_DEMO } from 'pages/LiveDemoPage'
import { FlowTraceLiveDemo, PATH_FLOW_TRACE_LIVE_DEMO } from 'pages/FlowTraceLiveDemo'
import { GuideIoPage } from 'pages/GuideIoPage'
import { GUIDE_IO_PROJECT_PATH } from 'components/guide-io/constants'
import { PATH_ROOT } from 'utils/links'
import { PATH_SIGN_UP_IO, SignUpIoPage } from 'pages/auth/SignUpIoPage'
import { DEV_PAGE_URL, DevPage } from 'pages/DevPage'
import { PATH_WELCOME_PAGE, WelcomePage } from 'pages/WelcomePage'

import { PATH_LITE_PROMO, LitePromoPage } from 'pages/lite/LitePromoPage'
import { PATH_LITE_SIGN_UP, LiteSignUpPage } from 'pages/lite/LiteSignUpPage'
import { PATH_LITE_TRACES, LiteTracesPage } from 'pages/lite/LiteTracesPage'
import { PATH_LITE_PS_CHART, LitePsChartPage } from 'pages/lite/LitePsChartPage'

import { liteRouter } from 'components/lite/liteRouter'
import { Arkanoid, PATH_ARKANOID } from 'pages/Arkanoid'

const authGuardedDevRoutes: RouteObject[] = [
  {
    path: MD_TO_HTML_PATH,
    element: <MdToHtmlPage />,
  },
  {
    path: CONTENTFUL_DEMO_PATH,
    element: <ContentfulDemoPage />,
  },
  {
    path: QUICK_START_PROTOTYPE_PAGE_PATH,
    element: <QuickStartPrototypePage />,
  },
  {
    path: VIDEO_NAVIGATION_PATH,
    element: <VideoNavPage />,
  },
  {
    path: STATIC_PATH_CHART,
    element: <StaticPsChartPage />,
  },
]

const defaultRouter = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          {
            path: PATH_ROOT,
            element: <MainPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="teams" />,
          },
          {
            path: DEV_PAGE_URL,
            element: <DevPage />,
          },
          {
            path: PATH_TEAM_START,
            element: <Navigate to={PATH_ROOT} replace />,
          },
          {
            path: PATH_TEAM,
            element: <TeamPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="teams" />,
          },
          {
            path: PATH_TEAM_CONTRIBUTORS,
            element: <TeamContributorsPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="teamContributors" />,
          },
          {
            path: PATH_PROJECT_CONTRIBUTORS,
            element: <ProjectContributorsPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="projectContributors" />,
          },
          {
            path: PATH_FLOWS_START,
            element: <Navigate to={PATH_FLOWS_END} replace />,
          },
          {
            path: PATH_PROJECTS,
            element: <Navigate to={PATH_ROOT} replace />,
          },
          {
            path: PATH_FLOWS,
            element: <FlowsPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="library" />,
          },
          {
            path: PATH_UNASSIGNED,
            element: <UnassignedPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="library" />,
          },
          {
            path: PATH_FLOW_START,
            element: <Navigate to={PATH_FLOW_END} replace />,
          },
          {
            path: PATH_FLOW_FULL_PATTERN,
            element: <FlowPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="library" />,
          },
          {
            path: PATH_TRACE_ADD,
            element: <TraceAddPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="library" />,
          },
          {
            path: PATH_CHART_ROUTER,
            element: <ChartRootPage />,
            children: [
              {
                path: '',
                element: <PsChartRouter />,
                errorElement: <ErrorPageAuthorized headerTitleKey="annotations" />,
              },
              {
                path: PATH_CHART_FOLDER,
                element: <PsChartPage />,
                errorElement: <ErrorPageAuthorized headerTitleKey="flameChart" />,
              },
              {
                path: PATH_ANNOTATIONS_FOLDER,
                element: (
                  <FeatureFlagRoute
                    flag={FEATURE_FLAGS.ANNOTATIONS}
                    redirectTo={`../${PATH_CHART_FOLDER}`}
                  >
                    <PsAnnotationsPage />
                  </FeatureFlagRoute>
                ),
                errorElement: <ErrorPageAuthorized headerTitleKey="annotations" />,
              },
              {
                path: PATH_PROCESSES_FOLDER,
                element: (
                  <FeatureFlagRoute
                    flag={FEATURE_FLAGS.ANNOTATIONS}
                    redirectTo={`../${PATH_CHART_FOLDER}`}
                  >
                    <PxAPage />
                  </FeatureFlagRoute>
                ),
                errorElement: <ErrorPageAuthorized headerTitleKey="pxa" />,
              },
            ],
          },
          {
            path: PATH_CHART_DIRECT,
            element: <PsChartPage />,
            errorElement: <ErrorPageAuthorized headerTitleKey="flameChart" />,
          },
          {
            path: GUIDE_IO_PROJECT_PATH,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.INTERACTIVE_ONBOARDING} redirectTo={PATH_ROOT}>
                <GuideIoPage />
              </FeatureFlagRoute>
            ),
          },
          {
            path: PATH_GUIDE_ROUTER,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.SELF_SERVE} redirectTo={PATH_INVITE_WHITELIST}>
                <GuidePage />
              </FeatureFlagRoute>
            ),
            children: [
              {
                path: PATH_GUIDE_SELECT_PLATFORM,
                element: <GuideSelectPlatform />,
              },
              {
                path: PATH_GUIDE_INSTRUMENT_AND_BUILD,
                element: <InstrumentAndBuild />,
              },
              {
                path: PATH_GUIDE_RECORD_TRACE,
                element: <RecordTrace />,
              },
              {
                path: PATH_GUIDE_VIEW_TRACE,
                element: <ViewTrace />,
              },
            ],
          },
          {
            path: PATH_WELCOME_PAGE,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.LIVE_DEMO} redirectTo={PATH_ROOT}>
                <WelcomePage />
              </FeatureFlagRoute>
            ),
          },
          {
            path: PATH_FREE_TRIAL_QUESTIONNAIRE,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.SELF_SERVE} redirectTo={PATH_INVITE_WHITELIST}>
                <FreeTrialQuestionnairePage />
              </FeatureFlagRoute>
            ),
          },
          {
            path: EXPIRED_TRIAL_PATH,
            element: <ExpiredTrialPage />,
          },
          {
            path: PATH_FLOW_TRACE_LIVE_DEMO,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.LIVE_DEMO_PER_FLOW} redirectTo={PATH_ROOT}>
                <FlowTraceLiveDemo />
              </FeatureFlagRoute>
            ),
            errorElement: <ErrorPageAuthorized headerTitleKey="liveDemo" />,
          },
          ...(isHostTypeProd() ? [] : authGuardedDevRoutes),
          {
            path: '*',
            element: <ErrorPageAuthorized />,
          },
          {
            path: PATH_LITE_TRACES,
            element: <LiteTracesPage />,
          },
          {
            path: PATH_LITE_PS_CHART,
            element: <LitePsChartPage />,
          },
        ],
      },
      {
        element: <SkipAuth />,
        children: [
          {
            path: PATH_SIGN_IN,
            element: <SignInPage />,
          },
          {
            path: PATH_INVITE_WHITELIST,
            element: <InviteWhitelistPage />,
          },
          {
            path: PATH_SIGN_UP,
            element: <SignUpPage />,
          },
          {
            path: PATH_SIGN_UP_SSO,
            element: <SignUpSsoPage />,
          },
          {
            path: PATH_RECOVERY_TOKEN,
            element: <RecoveryTokenPage />,
          },
          {
            path: PATH_RESET_PASSWORD,
            element: <ResetPasswordPage />,
          },
          {
            path: PATH_SIGN_UP_TRIAL,
            element: (
              <FeatureFlagRoute flag={FEATURE_FLAGS.SELF_SERVE} redirectTo={PATH_INVITE_WHITELIST}>
                <SignUpTrialPage />
              </FeatureFlagRoute>
            ),
          },
          {
            path: PATH_SIGN_UP_EARLY_BIRD,
            element: <SignUpEarlyBirdPage />,
          },
          {
            path: PATH_SIGN_UP_BETA,
            element: <SignUpEarlyBirdPage />,
          },
          {
            path: PATH_SIGN_UP_IO,
            element: (
              <FeatureFlagRoute
                flag={FEATURE_FLAGS.INTERACTIVE_ONBOARDING}
                redirectTo={PATH_INVITE_WHITELIST}
              >
                <SignUpIoPage />
              </FeatureFlagRoute>
            ),
          },
          {
            path: PATH_LITE_SIGN_UP,
            element: <LiteSignUpPage />,
          },
        ],
      },
      {
        path: PATH_LITE_PROMO,
        element: (
          <FeatureFlagRoute flag={FEATURE_FLAGS.PSTOOL_LITE} redirectTo={PATH_ROOT}>
            <LitePromoPage />
          </FeatureFlagRoute>
        ),
      },
      {
        path: PATH_LIVE_DEMO,
        element: (
          <FeatureFlagRoute flag={FEATURE_FLAGS.LIVE_DEMO} redirectTo={PATH_ROOT}>
            <LiveDemoPage />
          </FeatureFlagRoute>
        ),
      },
      {
        path: PATH_ARKANOID,
        element: <Arkanoid />,
      },
    ],
  },
])

export const router = !!process.env.REACT_APP_IS_LITE ? liteRouter : defaultRouter
