import { useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import type { Leaderboard } from 'pages/Arkanoid'
import { ArkanoidHeader } from './ArkanoidHeader'

export const ArkanoidLeaderboardScreen = ({
  onClick,
  leaderboard,
}: {
  onClick: () => void
  leaderboard: Leaderboard
}) => {
  const scrollToRef = useRef<HTMLElement>(null)

  useEffect(() => {
    if (scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [leaderboard])

  return (
    <div className="flex flex-col h-screen w-full bg-black font-turret-road font-bold text-[48px] leading-[100%]">
      <ArkanoidHeader hasLogo />
      <h1 className="text-[64px] text-center pt-[80px] pb-[40px] flex-none">Leaderboard</h1>
      <ul className="flex-grow overflow-auto pb-[110px] text-gray-faded">
        {leaderboard.map(({ name, score, isCurrent }, index) => (
          <li
            key={`${name}:${score}`}
            className={twMerge(
              'relative py-[32px] border-gray-strokeLight border-t-[1px]',
              isCurrent && 'bg-white text-black',
            )}
          >
            {isCurrent && <span ref={scrollToRef} className="absolute bottom-[-60px]" />}
            <span className="absolute left-[24px]">{index + 1}</span>
            <span className="text-center block w-full">{name}</span>
            <span
              className={twMerge(
                'absolute right-[25px] top-[32px] text-[#D4FF5B] font-normal',
                isCurrent && 'text-black',
              )}
            >
              {score}
            </span>
          </li>
        ))}
      </ul>
      <button
        onClick={onClick}
        className="bg-[#D4FF5B] text-black fixed bottom-0 left-0 w-full py-[32px] block flex-none"
      >
        Play Again
      </button>
    </div>
  )
}
