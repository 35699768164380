import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { LiteLayoutContainer } from 'components/lite/LiteLayout'

export function LiteFooter() {
  const { t } = useTranslation()
  return (
    <LiteLayoutContainer>
      <div className="flex justify-end mt-5 mb-20 text-normal flex-wrap">
        <Link
          to="https://docs.productscience.app/integration/android/gradle/"
          className="ml-5 underline whitespace-nowrap"
        >
          {t('lite.navigation.documentation')}
        </Link>
        <Link to="https://www.productscience.ai/terms" className="ml-5 underline whitespace-nowrap">
          {t('lite.navigation.tos')}
        </Link>
        <Link
          to="https://www.productscience.ai/privacy"
          className="ml-5 underline whitespace-nowrap"
        >
          {t('lite.navigation.privacyPolicy')}
        </Link>
        <span className="whitespace-nowrap ml-5">
          {t('lite.footer.copyright', { year: new Date().getFullYear() })}
        </span>
      </div>
    </LiteLayoutContainer>
  )
}
